<template>
  <div class="mb-8">
    <div>
      <div class="flex items-center justify-between">
        <h3 class="text-blue-800 font-semibold text-xl">Booking Options</h3>

        <div v-if="!edit">
          <Edit @edit="edit = true" />
        </div>
      </div>
    </div>

    <div class="mt-12">
      <template v-if="!edit">
        <div v-if="!optionsWithPrices.length || optionsWithPrices.length <= optionLength"
          class="h-12 border border-dashed rounded-lg bg-gray-50 flex items-center justify-center -mt-2">
          <span class="text-sm text-gray-600">No booking options added.</span>
        </div>

        <div v-else class="space-y-8">
          <div v-for="(option, index) in optionsWithPrices" :key="index"
            class="bg-white flex-1 booking-option-shadow flex sm:flex-row flex-col rounded-xl">
            <div class="flex flex-1">
              <div class="flex-shrink-0 flex justify-end py-6 sm:py-10 pl-6">
                <img :src="option.icon_url" class="h-8 w-8 lg:h-14 lg:w-14" alt="">
              </div>

              <div class="flex-1 py-6 sm:py-10 px-6">
                <div class="flex items-center space-x-3">
                  <h3 class="font-bold text-xl text-blue-800">{{ option.name }}</h3>
                </div>
                <p class="text-sm text-gray-500 mt-2">
                  {{ optionDescription(option.slug) }}
                </p>
              </div>
            </div>

            <div
              class="w-full sm:w-40 flex-shrink-0 border-t sm:border-t-0 sm:border-l border-guestio-gray-50 flex items-center flex-row sm:flex-col justify-center py-4 sm:py-0">
              <span class="text-xl font-bold text-blue-800">
                <template v-if="groupedPrices[option.id][0].price == 0">
                  Free
                </template>
                <template v-else>
                  ${{ groupedPrices[option.id][0].price }}
                </template>
              </span>

              <span v-if="groupedPrices[option.id][0].interview_duration" class="font-bold text-sm text-gray-500 mt-1">
                /{{ groupedPrices[option.id][0].interview_duration }} min
              </span>

              <span v-if="groupedPrices[option.id].length > 1" class="ml-6 sm:ml-0 sm:mt-2 text-xs text-purple-50">
                + {{ groupedPrices[option.id].length - 1 }} more
              </span>
            </div>
          </div>
        </div>
      </template>

      <template v-else>
        <ValidationObserver ref="bookingOptionsForm" v-slot="{ handleSubmit, invalid }" tag="div"
          class="max-w-xl w-full">
          <form method="post" @submit.prevent="handleSubmit(saveBookingOptions)">
            <div class="space-y-12">
              <template v-for="(option, index) in bookingOptions">
                <component :is="`${option.slug}-option`" :option="option" :prices="groupedPrices[option.id]"
                  :key="`booking-option-${index}`" :selected-options="flattenBookingOptions"
                  @updated="updateSelectedOptions(option.id, $event)" />
              </template>
            </div>

            <div class="mt-8 flex items-center justify-end">
              <button type="button" class="font-bold text-pink-500" @click="edit = false">
                Cancel
              </button>

              <button type="submit"
                class="ml-8 h-12 rounded-full px-10 py-3 bg-indigo-gradiant text-white disabled:opacity-75 font-bold"
                :disabled="invalid || saving">
                <loading-icon v-if="saving" class="text-white h-2" />
                <span v-else>Save</span>
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </div>
  </div>
</template>

<script>
import { chain } from 'lodash'
import api from '@/api'
import Edit from "@/components/shared/Edit"
import GuestAppearanceOption from "./booking-options/GuestAppearanceOption"
import SponsorOption from "./booking-options/SponsorOption"

export default {
  props: {
    show: Object,
  },

  components: {
    Edit,
    GuestAppearanceOption,
    SponsorOption
  },

  data() {
    return {
      working: false,
      edit: false,
      saving: false,
      bookingOptions: [],
      selectedOptions: {},
    }
  },

  computed: {
    flattenBookingOptions() {
      return chain(this.selectedOptions)
        .flatMap(options => options)
        .value()
    },

    optionsWithPrices() {
      return this.bookingOptions.filter(option => this.bookingOptionIds.includes(option.id))
    },

    bookingOptionIds() {
      return this.show.option_prices.map(price => price.booking_option_id)
    },

    groupedPrices() {
      if (!this.show.option_prices.length) {
        return {}
      }

      return chain(this.show.option_prices).groupBy(price => {
        return price.booking_option_id
      }).value()
    },

    optionLength() {
      let length = 0;

      if (this.groupedPrices[8]) {
        length = 1;
      }

      return length;
    }
  },

  created() {
    this.fetchBookingOptions()
  },

  mounted() {
    //
  },

  methods: {
    fetchBookingOptions() {
      api.get('/booking-options?filter[type]=show')
        .then(({ data }) => {
          this.bookingOptions = data.data.filter(option => option.slug != 'pitch');
        })
        .finally(() => this.working = false)
    },

    optionDescription(slug) {
      switch (slug) {
        case 'sponsor':
          return `Instead of paying for a 30-second ad spot, use this option to book a short form, value-based interview that’s built to promote what you want`
        case 'guest-appearance':
        default:
          return `Full-length organic interview on my Show.`
      }
    },

    updateSelectedOptions(optionId, data) {
      this.$set(this.selectedOptions, optionId, data)
    },

    refetchShow() {
      this.$store.dispatch('shows/fetchShow', {
        id: this.$route.params.showId,
        includes: 'pastGuests,showTypes'
      })
    },

    async saveBookingOptions() {

      let data = this.flattenBookingOptions.map(({ ...rest }) => rest)


      if (data.filter((data) => data.booking_option_id == 6 && data.price == 'higer_price').length > 0) {

        const formData = new FormData()

        this.saving = true;

        data.map((res, index) => {
          formData.append(`option[${index}]`, JSON.stringify(res));
          if (res.file) {
            formData.append(`file[]`, res.file);
          }
        })


        api.post(`/shows/${this.show.id}/booking-options-request`, formData).then(() => {
          this.$toast.success('Data saved successfully! Team will conntact you soon')
          this.edit = false
        }).catch((error) => {
          console.log(error);
        }).finally(() => {
          this.saving = false;
        })




      } else {
        let confirmed = await this.confirmLogin()

        data.map((res) => {
          delete res.file
        })

        if (!confirmed) {
          return
        }

        this.saving = true;

        api.post(`/shows/${this.show.id}/booking-options`, {
          // eslint-disable-next-line no-unused-vars
          options: this.flattenBookingOptions.map(({ booking_option, ...rest }) => rest).map((res) => {
            delete res.file
            return res
          }),
        })
          .then(() => {
            this.$toast.success('Booking options saved')
            this.edit = false
            this.refetchShow()
          })
          .catch(() => {
            this.$toast.error('Error! Something went wrong.')
          })
          .finally(() => {
            this.saving = false
          })
      }


    }
  }
}
</script>
